
import React, { useState, useReducer, useEffect, useMemo, useRef } from 'react';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Tooltip, Container, Checkbox, Chip, FormControlLabel, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";

function keepLastOccurrence(text, word) {
    const words = text.split(/\s+/);
    const indexes = [];
    for (let i = 0; i < words.length; i++) {
        if (words[i] === word) {
            indexes.push(i);
        }
    }
    if (indexes.length === 0) {
        return text;
    }
    const lastIndex = indexes[indexes.length - 1];
    const resultWords = [];
    for (let i = 0; i < words.length; i++) {
        if (i !== lastIndex && words[i] === word) {
            continue;
        }
        resultWords.push(words[i]);
    }
    return resultWords.join(' ');
}

function Errors(props) {

    const [selectedEmpathies, setSelectedEmpathies] = useState([]);
    const [selectedErrors, setSelectedErrors] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);


    const options2 = {
        ovenSettings: [
            {
                label: "Подгорел", errorText: "перепечённую XXX", actionText: "проверим настройки печи",
                empathy: [
                    "Стыдно, что она подгорела.",
                    "Понимаем, как неприятно получить подгоревший продукт."
                ],
                questions: [
                    "Есть вкус или запах гари?",
                    "Начинка тоже подгорела?",
                    "Подгорели только бортики или дно пиццы тоже чёрное?"
                ],
            },
            {
                label: "Недопечён/Сырой", errorText: "недопечённую XXX", actionText: "проверим настройки печи",
                empathy: [
                    "Стыдно, что приготовили не до конца.",
                    "Понимаем, как неприятно получить сырой продукт."
                ],
                questions: [
                    "Недопечено тесто или ингредиенты?",
                    "Чувствуется сырой вкус?",
                    "В бортиках есть прослойка сырого теста?",
                    "Какого цвета бортики?",
                    "Сыр внутри расплавился?",
                    "Пробовали крылья раньше?",
                    "Мясо в крыльях розове/с кровью?",
                    "Картофель золотистый или бледный?",
                    "Края картофеля подрумянены сильнее?",
                    "Есть вкус или запах гари?",
                    "Начинка тоже подгорела?",
                    "Подгорели только бортики или дно пиццы тоже чёрное?"
                ],
            },
            {
                label: "Сухой", errorText: "сухую XXX", actionText: "проверим настройки печи",
                empathy: [
                    "Стыдно, что она заветрилась.",
                    "Понимаем, как важно получить сочный продукт."
                ],
                questions: [
                    "Тесто сухое и твёрдое, оно хрустело?",
                    "Начинка сухая?"
                ],
            },
            {
                label: "Твёрдый", errorText: "твёрдую XXX", actionText: "проверим настройки печи",
                empathy: [
                    "Стыдно, что привезли жёсткий продукт.",
                    "Понимаем, как неприятно получить жёсткий продукт."
                ],
                questions: [
                    "Тесто сухое и твёрдое, оно хрустело?",
                    "Начинка сухая?"
                ],
            },
        ],
        attentiveness: [
            {
                label: "Забыли", errorText: "забытую XXX", actionText: "будем внимательнее собирать заказы",
                empathy: [
                    "Стыдно, что не привезли её.",
                    "Понимаем, как неприятно не получить нужный продукт."
                ],
                questions: [
                    "Соус кладём в отдельные отсеки в самой коробке. Отсеки закрыты и соуса в них нет?",
                    "Обнаружили отсутствие при курьере? Он или менеджер обещали довезти?"
                ],
            },
            {
                label: "Перепутали", errorText: "перепутанную XXX", actionText: "будем внимательнее собирать заказы",
                empathy: [
                    "Стыдно, что привезли не то, что вы выбрали.",
                    "Понимаем, как неприятно не получить нужный продукт."
                ],
                questions: [
                    "Какого продукта не хватает и что привезли вместо него?",
                    "Перепутали именно продукт или какие-то ингредиенты в нём?",
                    "Какая цифра указана на коробке?",
                    "Какой продукт и номер заказа указан на этикетке?"
                ],
            },
            {
                label: "Нечитаемая этикетка", errorText: "нечитаемую этикетку", actionText: "будем внимательнее собирать заказы",
                empathy: [
                    "Стыдно, что не уделили должного внимания.",
                    "Понимаем, как важно иметь возможность получить информацию из маркировки."
                ],
                questions: [

                ],
            },
            {
                label: "Отсутствует этикетка", errorText: "отсутствие этикетки", actionText: "будем внимательнее собирать заказы",
                empathy: [
                    "Стыдно, что привезли продукт без маркировки.",
                    "Понимаем, как важно иметь возможность получить информацию из маркировки."
                ],
                questions: [

                ],
            },
            {
                label: "Упакован не по стандарту", errorText: "продукт упакованный не по стандарту", actionText: "будем внимательнее собирать заказы",
                empathy: [
                    "Жаль, что так вышло.",
                ],
                questions: [

                ],
            },
            {
                label: "Продукт нагрелся", errorText: "тёплый XXX", actionText: "будем внимательнее собирать заказы",
                empathy: [
                    "Стыдно, что он нагрелся.",
                    "Понимаем, как важно получить его прохладным.",
                ],
                questions: [
                    "Курьер достал напиток из термосумки, где лежали горячие продукты?",
                    "Лимонад просто не из холодильника или нагрелся из-за соседства с горячими продуктами?"
                ],
            },
        ],
        speed: [
            {
                label: "Опоздали", errorText: "опоздание", actionText: "скорректируем график работы сотрудников",
                empathy: [
                    "Стыдно, что не смогли доставить заказ вовремя.",
                    "Понимаем, как важно получить заказ вовремя."
                ],
                questions: [
                    "Во сколько приехал курьер?",
                    "Получили СМС-сообщение с промокодом на пиццу?",
                    "Промокод на пиццу должен был прийти в СМС-сообщении и отобразиться в личном кабинете в разделе «Акции». Подскажите, вы получили его?"
                ],
            },
            {
                label: "Долгое ожидание на кассе", errorText: "долгую выдачу заказа", actionText: "скорректируем график работы сотрудников",
                empathy: [
                    "Стыдно, что не смогли отдать заказ вовремя.",
                    "Понимаем, как важно получить заказ вовремя."
                ],
                questions: [

                ],
            },
        ],
        standarts: [
            {
                label: "Перепутали ингредиент", errorText: "перепутанный ингредиент", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что неверно приготовили продукт.",
                    "Понимаем, как это неприятно."
                ],
                questions: [
                    "Какого ингредиента не хватает? Что положили вместо него?"
                ],
            },
            {
                label: "Проблема с тестом", errorText: "проблему с тестом", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что неверно приготовили продукт.",
                    "Понимаем, как это неприятно."
                ],
                questions: [
                    "Бортики одинаковой ширины?",
                    "Бортики были плоские или просто не пышные?"
                ],
            },
            {
                label: "Лишний ингредиент", errorText: "лишний ингредиент", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что неверно приготовили продукт.",
                    "Понимаем, как это неприятно."
                ],
                questions: [
                    "Какой ингредиент добавили? Что положили вместо него?"
                ],
            },
            {
                label: "Не положили ингредиент", errorText: "отсутствие ингредиента", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что неверно приготовили продукт.",
                    "Понимаем, как это неприятно."
                ],
                questions: [
                    "Какого ингредиента не хватает? Что положили вместо него?"
                ],
            },
            {
                label: "Малое количество начинки", errorText: "малое количество начинки", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что неверно приготовили продукт.",
                    "Понимаем, как это неприятно."
                ],
                questions: [
                    "Ингредиента мало или нет совсем?"
                ],
            },
            {
                label: "Пицца не порезана", errorText: "плохо порезанную пиццу", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что так вышло.",
                    "Понимаем, как это неприятно."
                ],
                questions: [
                    "Продукт порезан не до конца или не порезан полностью?"
                ],
            },
            {
                label: "Пицца порезана неровно", errorText: "неровно порезанную пиццу", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что так вышло.",
                    "Понимаем, как это неприятно."
                ],
                questions: [
                    "Кусочки разного размера?",
                    "Сколько кусочков в пицце?"
                ],
            },
            {
                label: "Ингредиенты нарезаны неровно", errorText: "неровно порезанный XXX", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что неверно приготовили продукт.",
                    "Понимаем, как это неприятно."
                ],
                questions: [
                    "Кусочки ингредиентов разного размера?",
                    "Все ингредиенты нарезаны неровно?"
                ],
            },
            {
                label: "Сладкий/Острый/Жирный", errorText: "слишком XXX продукт", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что неверно его приготовили.",
                ],
                questions: [
                    "Раньше пробовали ХXX?",
                    "Солёным/Сладким/Жирным кажется весь продукт или какой-то ингредиент?"
                ],
            },
            {
                label: "Неравномерно распределены ингредиенты", errorText: "неравномерно распределённую начинку", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что неверно приготовили продукт.",
                    "Согласны, что пицца не должна так выглядеть.",
                ],
                questions: [
                    "На кусочках разное количество ингредиентов?",
                    "Есть кусочки без ингредиента или «голые» участки?"
                ],
            },
            {
                label: "Неверный вес", errorText: "несоответствия с заявленным весом", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Стыдно, что добавили меньше ингредиентов, чем требовалось.",
                    "Согласны, что ингредиентов должно быть больше.",
                    "Согласны, что он должен быть больше.",
                ],
                questions: [
                    "Взвешивали ХХХ вместе с коробкой?"
                ],
            },
            {
                label: "Неверный размер", errorText: "несоответствия с заявленным размером", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Согласны, что он должен быть больше.",
                ],
                questions: [
                    "На сколько XXX меньше?",
                    "Какой продукт/размер указан на этикетке?",
                ],
            },
            {
                label: "Сыр не тянется", errorText: "твёрдый сыр", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Согласны, что он должен тянуться.",
                ],
                questions: [
                    "XXX горячий, тёплый?",
                    "Сыр в бортиках расплавился, тянется?"
                ],
            },
            {
                label: "Продукт заморожен", errorText: "плохо размороженный XXX", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Согласны, так быть не должно.",
                ],
                questions: [
                    "Десерт просто холодный или со льдом?",
                    "Какие даты указаны на маркировке?"
                ],
            },
            {
                label: "Горький", errorText: "горький XXX", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Согласны, так быть не должно.",
                ],
                questions: [
                    "Раньше пробовали ХXX?",
                    "Горьким кажется соус, ингредиент или весь продукт?"
                ],
            },
            {
                label: "Кислый", errorText: "кислый XXX", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Согласны, так быть не должно.",
                ],
                questions: [
                    "Раньше пробовали ХXX?",
                    "Кислым кажется соус, ингредиент или весь продукт?"
                ],
            },
            {
                label: "Пресный", errorText: "пресный XXX", actionText: "повторим стандарты приготовления",
                empathy: [
                    "Согласны, так быть не должно.",
                ],
                questions: [
                    "Раньше пробовали ХXX?",
                    "Безвкусный какой-то ингредиент или весь продукт?"
                ],
            },

        ],
        accuracy: [
            {
                label: "Пролили/Разлили", errorText: "разлитый напиток", actionText: "постараемся аккуратнее доставлять заказы",
                empathy: [
                    "Стыдно, что пролили его.",
                    "Понимаем, как неприятно получить его в таком виде.",
                ],
                questions: [
                    "Большая часть была разлита?",
                ],
            },
            {
                label: "Мятый", errorText: "смятую XXX", actionText: "постараемся аккуратнее доставлять заказы",
                empathy: [
                    "Стыдно, что помяли её.",
                    "Понимаем, как неприятно получить её в таком виде.",
                ],
                questions: [
                    "Весь продукт был мятый? Или только часть?",
                    "Начинка была разбросана по упаковке?"
                ],
            },
            {
                label: "Кусочки разъехались", errorText: "смятую XXX", actionText: "постараемся аккуратнее доставлять заказы",
                empathy: [
                    "Понимаем, как неприятно получить её в таком виде.",
                ],
                questions: [
                    "Кусочки лежат друг на друге?",
                    "Кусочки пиццы разъехались?",
                ],
            },
            {
                label: "Начинка вытекла", errorText: "вытекшую начинку", actionText: "постараемся аккуратнее доставлять заказы",
                empathy: [
                    "Понимаем, как неприятно получить продукт в таком виде.",
                ],
                questions: [
                    "Начинка вытекла в коробку или осталась на тесте, но перемешалась?",
                    "Вытекла большая часть начинки?",
                    "Сыр остался в бортиках или весь вытек?"
                ],
            },
            {
                label: "Прилип к упаковке", errorText: "продукт в таком виде", actionText: "постараемся аккуратнее доставлять заказы",
                empathy: [
                    "Понимаем, как это неприятно.",
                ],
                questions: [
                    "Часть начинки осталась на коробке или весь продукт прилип к упаковке?",
                    "Сыр прилип к коробке в местах разреза или к крышке?"
                ],
            },
        ],
        thermalbags: [
            {
                label: "Продукт холодный/Остыл", errorText: "остывшую XXX", actionText: "проверим термосумки",
                empathy: [
                    "Понимаем, как важно получить горячие продукты.",
                    "Стыдно, что привезли холодные продукты."
                ],
                questions: [
                    "Продукт был тёплый или полностью остыл?",
                    "У курьера была термосумка?",
                    "Когда забирали Х, дно коробки было тёплым?",
                    "Вы стали есть пиццу сразу после получения?",
                    "Подскажите, во сколько приехал курьер.",
                ],
            },
        ],
        neighborhood: [
            {
                label: "Соседство с пиццерией/Неправильная парковка", errorText: "неудобства связанные с соседством", actionText: "повторим правила парковки",
                empathy: [
                    "Согласны, такого быть не должно.",
                ],
                questions: [

                ],
            },
        ],
        ingredients: [
            {
                label: "Испорченый вид", errorText: "испорченный XXX", actionText: "проверим все ингредиенты",
                empathy: [
                    "Согласны, такого быть не должно.",
                ],
                questions: [

                ],

            },
            {
                label: "Ипорченный запах", errorText: "посторонний запах у XXX", actionText: "проверим все ингредиенты",
                empathy: [
                    "Согласны, такого быть не должно.",
                ],
                questions: [

                ],
            },
            {
                label: "Ипорченный вкус", errorText: "посторонний вкус у XXX", actionText: "проверим все ингредиенты",
                empathy: [
                    "Согласны, такого быть не должно.",
                ],
                questions: [

                ],
            },
            {
                label: "Срок годности истёк", errorText: "испорченный XXX", actionText: "проверим все ингредиенты",
                empathy: [
                    "Согласны, такого быть не должно.",
                ],
                questions: [
                    "Какие даты указаны на маркировке?",
                    "Какие даты указаны на бутилированном напитке? Что указано на первой дате?",
                    "Сколько дат указано на продукте: две или три? Что указано на последней дате?",
                ],
            },
            {
                label: "Посторонний вкус или запах", errorText: "посторонний запах/вкус", actionText: "проверим все ингредиенты",
                empathy: [
                    "Согласны, такого быть не должно.",
                ],
                questions: [
                    "Этот вкус/запах — не пищевой?"
                ],
            },
        ],

        status: [
            {
                label: "Отмечен выданным раньше времени", errorText: "отметку раньше времени", actionText: "повторим как правильно выставлять статусы",
                empathy: [
                    "Согласны, такого быть не должно.",
                ],
                questions: [],
            },
        ],

        other: [
            {
                label: "Долгий ответ", errorText: "долгий ответ", actionText: false, empathy: false,
                questions: [

                ],
            },
        ],

    }

    const defalutEmpathy = [
        "Жаль, что так вышло.",
        //"Не оставим проблему без внимания и разберёмся.",
        "Согласны, такого быть не должно.",
        "Понимаем, как это неприятно.",
        "Сожалеем, что вы столкнулись с подобным сервисом.",
        "Хотим вернуть ваше доверие и доказать, что можем лучше.",
        //"В будущем постараемся не подводить, а только радовать заказами.",
        "Ваш отзыв важен для нас, спасибо, что рассказали.",
        "Спасибо, что рассказали, это важно.",
        "Учтём фидбек и исправимся, стыдно, что подвели.",
        "Стыдно, что подвели.",
        "Стыдно, что оставили такое впечатление, понимаем ваши чувства, подобное недопустимо.",
    ];



    const flattenedOptions = Object.values(options2).flat();
    const errorMap = Object.fromEntries(flattenedOptions.map(o => [o.label, o.errorText]));
    const actionMap = Object.fromEntries(flattenedOptions.map(o => [o.label, o.actionText]));

    const empathyOptions = [
        ...defalutEmpathy,
        ...flattenedOptions.flatMap(option => option.empathy || [])
    ];

    const getEmpathyOptions = (errors) => {
        const relatedEmpathiesSet = new Set(
            errors.flatMap(error => {
                const relatedOptions = options2[Object.keys(options2).find(key =>
                    options2[key].some(o => o.label === error.label)
                )]?.find(o => o.label === error.label)?.empathy || [];
                return relatedOptions;
            })
        );
        const relatedEmpathies = Array.from(relatedEmpathiesSet);
        const defaultEmpathySet = new Set(defalutEmpathy);
        const remainingEmpathiesSet = new Set(defalutEmpathy);
        relatedEmpathies.forEach(emp => remainingEmpathiesSet.delete(emp));
        const remainingEmpathies = Array.from(remainingEmpathiesSet);

        return [
            ...relatedEmpathies,
            ...remainingEmpathies
        ];
    };

    const getQuestionsOptions = (errors) => {
        const relatedQuestionsSet = new Set(
            errors.flatMap(error => {
                const relatedOptions = options2[Object.keys(options2).find(key =>
                    options2[key].some(o => o.label === error.label)
                )]?.find(o => o.label === error.label)?.questions || [];
                return relatedOptions;
            })
        );
        return Array.from(relatedQuestionsSet);
    };

    useEffect(() => {
        if (selectedErrors.length === 0) {
            setSelectedEmpathies([]);
        } else if (selectedErrors.length > 1) {
            setSelectedEmpathies([defalutEmpathy[Math.floor(Math.random() * defalutEmpathy.length)]]);
        } else {
            const relatedEmpathies = selectedErrors.flatMap(error =>
                options2[Object.keys(options2).find(key =>
                    options2[key].some(o => o.label === error.label)
                )]?.find(o => o.label === error.label)?.empathy || []
            );
            if (relatedEmpathies.length > 0) {
                setSelectedEmpathies([relatedEmpathies[Math.floor(Math.random() * relatedEmpathies.length)]]);
            } else {
                setSelectedEmpathies([defalutEmpathy[Math.floor(Math.random() * defalutEmpathy.length)]]);
            }
        }
    }, [selectedErrors]);

    const handleErrorsChange = (event, values) => {
        setSelectedErrors(values);
    };

    useEffect(() => {
        generateErrorText(selectedErrors);
    }, [selectedErrors, selectedQuestions, selectedEmpathies]);

    const groupActions = (actions) => {
        const uniqueActions = [...new Set(actions)];

        const groupedActions = {};

        uniqueActions.forEach((action) => {
            const key = action.split(" ")[0];
            if (groupedActions[key]) {
                groupedActions[key].push(action.replace(key, "").trim());
            } else {
                groupedActions[key] = [action.replace(key, "").trim()];
            }
        });

        return Object.entries(groupedActions).map(
            ([key, phrases], index) => {
                const cleanedPhrases = phrases.map((phrase, i) => {
                    let cleanedPhrase = i === 0 ? phrase : phrase.replace(/^\s*(быть|внимательнее)\s*/, '');
                    return cleanedPhrase;
                });
                const actionPhrase = `${key} ${cleanedPhrases.join(" и ")}`;
                return index === 0 ? `${actionPhrase.charAt(0).toUpperCase()}${actionPhrase.slice(1)}` : actionPhrase;
            }
        ).join(", ");
    };

    const generateErrorText = (errors) => {

        let empathyText = selectedEmpathies.length ? selectedEmpathies.join(" ") : "";

        if (errors.length === 0) {
            props.setErrorText(empathyText);
            return;
        }

        const selectedTexts = errors.map((error) => errorMap[error.label]);
        const actionTexts = errors
            .map((error) => actionMap[error.label])
            .filter(Boolean);

        let apologies = `Простите за ${selectedTexts
            .join(", ")
            .replace(/, ([^,]*)$/, " и $1")}.`;

        if (errors.length > 3) {
            apologies = "Простите за большое количество ошибок в заказе.";
        }

        let actions = groupActions(actionTexts);
        actions = keepLastOccurrence(actions, "заказы");

        const lastPhrases = [
            `${actions}, чтобы избежать подобных ситуаций.`,
            `${actions}, чтобы не повторить ошибку снова.`,
            `Передадим информацию о проблеме команде и проработаем ошибки: ${actions.toLowerCase()}.`,
            `Не оставим проблему без внимания и проработаем её с командой: ${actions.toLowerCase()}.`,
        ];
        const actionSentence = actions.length ? `${lastPhrases[Math.floor(Math.random() * lastPhrases.length)]}` : "";



        const questionText = selectedQuestions.length ? `${selectedQuestions.join(" ")}` : "";
        console.log(selectedQuestions)

        props.setErrorText(`${apologies} ${empathyText} ${actionSentence} ${questionText}`);
        props.setErrorCount(errors.length);
    };

    return (
        <Paper sx={{ padding: "10px" }}>
            {/* <Box sx={{display: "flex", justifyContent: "center"}}>
                <Typography>Проработка</Typography>
            </Box> */}
            <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                <Autocomplete
                    fullWidth
                    multiple
                    options={flattenedOptions}
                    getOptionLabel={(option) => option.label}
                    onChange={handleErrorsChange}
                    renderInput={(params) => <TextField {...params} label="Выбери ошибки" />}
                />
                <Autocomplete
                    fullWidth
                    multiple
                    options={getQuestionsOptions(selectedErrors)}
                    value={selectedQuestions}
                    onChange={(event, values) => setSelectedQuestions(values)}
                    renderInput={(params) => <TextField {...params} label="Выбери уточняющие вопросы" />}
                />
            </Box>
            <Autocomplete
                fullWidth
                multiple
                options={getEmpathyOptions(selectedErrors)}
                value={selectedEmpathies}
                renderOption={(props, option) => {
                    const isRelated = selectedErrors.some(error => options2[Object.keys(options2).find(key =>
                        options2[key].some(o => o.label === error.label)
                    )]?.find(o => o.empathy && o.empathy.includes(option)));
                    return (
                        <ListItem {...props} style={{ backgroundColor: isRelated ? 'green' : 'transparent' }}>
                            <ListItemText primary={option} />
                        </ListItem>
                    );
                }}
                onChange={(event, values) => setSelectedEmpathies(values)}
                renderInput={(params) => <TextField {...params} label="Выбери слова эмпатии" />}
            />

        </Paper>
    );
}

export default Errors;